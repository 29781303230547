import React from "react";
import BenefitSingle from "./benefitSingle";

const Benefits = ({ benefits, header, extraClass, startTextLeft = false, lang }) => {
  let layoutCount = 0;
  if (startTextLeft) {
    layoutCount = 1;
  }
  return (
    <div className="container">
      <div className="benefits">
        {header && header.title && (
          <h2 className={extraClass ? `${extraClass}h2` : ""}>
            {header.title}
          </h2>
        )}
        {header && header.description && (
          <p className={extraClass ? `${extraClass}p` : ""}>
            {header.description()}
          </p>
        )}
        {benefits && benefits.length
          ? benefits.map((f, i) => (
            <BenefitSingle
              key={i}
              feature={f}
              isTextSideRight={(i + layoutCount) % 2 === 0 ? true : false}
              lang={lang}
            >
              {f.description()}

            </BenefitSingle>
          ))
          : null}
      </div>
    </div>
  );
};

export default Benefits;
