import React, { useState } from "react";
import Button from "./button";

const QA = ({ qas, lang }) => {
  const head = qas && qas.allLabels && qas.allLabels.edges && qas.allLabels.edges.find((el) => el.node.label === "more-questions-title")
  const text = qas && qas.allLabels && qas.allLabels.edges && qas.allLabels.edges.find((el) => el.node.label === "more-questions-text")

  if (
    !qas ||
    !qas.allContentfulFaq ||
    qas.allContentfulFaq.edges.length === 0
  ) {
    return <p>loading</p>;
  }
  return (
    <div className="container">
      <div className="qa" id="faq">
        <h3>{head && head.node[lang]}</h3>
        <p>{text && text.node[lang]}</p>
        <QuestionsAndAnswers qas={qas.allContentfulFaq.edges} lang={lang} />
        {/* <Button className="withArrow blackbtn">Περισσότερες απαντήσεις</Button> */}
      </div>
    </div>
  );
};

const QuestionsAndAnswers = ({ qas = [], lang }) => {
  let initState = qas.map(i => false);
  initState[0] = true;
  const [open, setOpen] = useState(initState);
  function toggleOpen(index) {
    setOpen(open.map((i, j) => j === index));
  }
  if (!qas || qas.length === 0) {
    return <p>nothing</p>;
  }
  return (
    <div className="qaBox">
      {qas.map((q, i) => (
        <SingleQuestion
          key={i}
          index={i}
          q={q.node}
          state={open}
          toggleOpen={toggleOpen}
          lang={lang}
        />
      ))}
    </div>
  );
};

const SingleQuestion = ({ q, state, toggleOpen, index, lang }) => {
  return (
    <div className="accordion" onClick={e => toggleOpen(index)}>
      <div className="question">
        <div className="text">{lang === "gr" ? q.question : q.questionInEnglish}</div>
        <div className="icon">
          {state[index] ? (
            <img alt="up arrow" src="/up-arrow.svg" />
          ) : (
            <img alt="down arrow" src="/down-arrow.svg" />
          )}
        </div>
      </div>
      {state[index] && <div className="answer">{lang === "gr" ? q.answ.answ : q.answerInEnglish.answerInEnglish}</div>}
      <div className="hr hrthin hrfull mgtop15 hrpeach">&nbsp;</div>
    </div>
  );
};

export default QA;
