import React from "react";

const Experts = ({ data, lang }) => {

  const head = data && data.allLabels && data.allLabels.edges && data.allLabels.edges.find((el) => el.node.label === "our-experts-title")
  const subText = data && data.allLabels && data.allLabels.edges && data.allLabels.edges.find((el) => el.node.label === "our-experts-text")
  const expert1 = data && data.allLabels && data.allLabels.edges && data.allLabels.edges.find((el) => el.node.label === "our-expert-name-1")
  const expert2 = data && data.allLabels && data.allLabels.edges && data.allLabels.edges.find((el) => el.node.label === "our-expert-name-2")
  const expertSubtext1 = data && data.allLabels && data.allLabels.edges && data.allLabels.edges.find((el) => el.node.label === "our-expert-1-description-para-1")
  const expertSubtext2 = data && data.allLabels && data.allLabels.edges && data.allLabels.edges.find((el) => el.node.label === "our-expert-2-description-para-1")
  const expertSubtexttext1 = data && data.allLabels && data.allLabels.edges && data.allLabels.edges.find((el) => el.node.label === "our-expert-1-description-para-2")
  const expertSubtexttext2 = data && data.allLabels && data.allLabels.edges && data.allLabels.edges.find((el) => el.node.label === "our-expert-2-description-para-2")
  const expertSubtexttexttext1 = data && data.allLabels && data.allLabels.edges && data.allLabels.edges.find((el) => el.node.label === "our-expert-2-description-para-3")

  return (
    <div className="container">
      <div className="experts">
        <h3>{head && head.node[lang]}</h3>
        <p>
          {subText && subText.node[lang]}
        </p>
        <div className="expertsRow">
          <div className="expert left">
            <div className="expertMargin"></div>
            <div className="expertText">
              <h4>{expert1 && expert1.node[lang]}</h4>
              <p>{expertSubtext1 && expertSubtext1.node[lang]}</p>
              <p>
                {expertSubtexttext1 && expertSubtexttext1.node[lang]}
              </p>
            </div>
          </div>
          <div className="expertPhoto"></div>
          <div className="expert right">
            <div className="expertMargin"></div>
            <div className="expertText">
              <h4>{expert2 && expert2.node[lang]}</h4>
              <p>{expertSubtext2 && expertSubtext2.node[lang]}</p>
              <p>
                {expertSubtexttext2 && expertSubtexttext2.node[lang]}
              </p>
              <p>
                {expertSubtexttexttext1 && expertSubtexttexttext1.node[lang]}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="clearall"></div>
    </div>
  );
};

export default Experts;
