import { useState } from "react";
import React from "react";

const Timeline = ({ stages, data }) => {
  const [lang, setLang] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "gr"
      : "gr"
  );

  const ques1 = data && data.find((el) => el.node.label === "vitamin-que-1")
  const answ1 = data && data.find((el) => el.node.label === "vitamin-que-1-text")
  return (< div className="container" >
    <div className="timeline">
      <h2>{ques1 && ques1.node[lang]}</h2>
      <p>
        {answ1 && answ1.node[lang]}{" "}
      </p>
      {stages && stages.length
        ? stages.map((f, i) => (
          <StageSingle
            key={i}
            feature={f}
            isTextSideRight={i % 2 === 0 ? true : false}
            isLast={i === stages.length - 1 ? true : false}
          >
            {f.description()}
          </StageSingle>
        ))
        : null}
    </div>
  </div >)
};

export default Timeline;

const StageSingle = ({ feature, children, isLast, isTextSideRight = true }) => {
  const addLeftClass = originalClass => {
    if (isTextSideRight === true && originalClass === "sideImgHolder") {
      return originalClass + " left";
    } else if (isTextSideRight === false && originalClass === "stage") {
      return originalClass + " left";
    }
    return originalClass + " right";
  };
  const addLastClass = originalClass => {
    if (isLast) {
      return originalClass + " lastNoBorder";
    } else {
      return originalClass;
    }
  };
  return (
    <div className="stageRow">
      <div className={addLastClass(addLeftClass("sideImgHolder"))}>
        <div
          className="sideImg"
          style={{ backgroundImage: `url("${feature.image}")` }}
        ></div>
      </div>
      <div className={addLastClass(addLeftClass("stage"))}>
        <div className="stageTextHolder">
          <h3>{feature.title}</h3>
          {children}
        </div>
      </div>
    </div>
  );
};
