import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import VitaminMainCTA from "../components/vitaminMainCTA";
import VitaminIntro from "../components/vitaminIntro";
import Timeline from "../components/timeline";
import Benefits from "../components/benefits";
import IngredientsHolder from "../components/ingredientsHolder";
import Experts from "../components/experts";
import QA from "../components/qa";
import Follow from "../components/follow";
import { HalfButton2, Guarantees } from "../components/button";

const SecondPage = ({ data }) => {
  const [lang, setLang] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "gr"
      : "gr"
  );

  const timeline1 = data.allLabels.edges.find(
    el => el.node.label === "vit-timeline-1"
  );
  const timelinesub1 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-1-ans-1"
  );
  const timelinesub2 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-1-ans-2"
  );
  const timelinesub3 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-1-ans-3"
  );

  const timeline2 = data.allLabels.edges.find(
    el => el.node.label === "vit-timeline-2"
  );
  const timeline3 = data.allLabels.edges.find(
    el => el.node.label === "vit-timeline-3"
  );
  const benefithead = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2"
  );
  const benefitsubhead = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-text"
  );
  const title1 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-1-title"
  );
  const title2 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-2-title"
  );
  const title3 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-3-title"
  );
  const title4 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-4-title"
  );
  const title5 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-5-title"
  );
  const title6 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-6-title"
  );
  const description1 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-1"
  );
  const description2 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-2"
  );
  const description3 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-3"
  );
  const description4 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-4"
  );
  const description5 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-5"
  );
  const description6 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-6"
  );

  const btntext1 = data.allLabels.edges.find(
    el => el.node.label === "vitamin-que-2-ans-4-button"
  );
  const btntext2 = data.allLabels.edges.find(
    el => el.node.label === "home-section-7-button"
  );
  console.log("data", timelinesub2 && timelinesub2.node[lang].split("\r"))
  const stages = [
    {
      title: timeline1 && timeline1.node[lang],
      description: () => (<>
        <p style={{ marginBottom: "26px" }}>{timelinesub1 && timelinesub1.node[lang].split("\r")[0]}</p>
        {timelinesub1 && timelinesub1.node[lang].split("\r").slice(2).map((el) => <p style={{ margin: "0" }}>✔︎ {el}</p>)}</>
      ),
      image: "/stage-1-image2@2x.jpg",
    },
    {
      title: timeline2 && timeline2.node[lang],
      description: () => (<>
        <p style={{ marginBottom: "26px" }}>{timelinesub2 && timelinesub2.node[lang].split("\r")[0]}</p>
        {timelinesub2 && timelinesub2.node[lang].split("\r").slice(2).map((el) => <p style={{ margin: "0" }}>{el}</p>)}
      </>

      ),
      image: "/stage-2-image2@2x.jpg",
    },
    {
      title: timeline3 && timeline3.node[lang],
      description: () => (
        <>
          <p style={{ marginBottom: "26px" }}>{timelinesub3 && timelinesub3.node[lang].split("\r")[0]}</p>
          {timelinesub3 && timelinesub3.node[lang].split("\r").slice(2).map((el) => <p style={{ margin: "0" }}>{el}</p>)}
        </>

      ),
      image: "/stage-3-image2@2x.jpg",
    },
  ];

  const benefits = {
    header: {
      title: benefithead && benefithead.node[lang],
      description: () => (
        <span>
          {benefitsubhead &&
            benefitsubhead.node[lang]
              .split(" ")
              .slice(0, 4)
              .map(el => el)
              .join(" ")}{" "}
          <strong>{benefitsubhead.node[lang].split(" ")[4]}</strong>{" "}
          {benefitsubhead &&
            benefitsubhead.node[lang]
              .split(" ")
              .slice(5)
              .map(el => el)
              .join(" ")}
        </span>
      ),
    },
    items: [
      {
        title: title1 && title1.node[lang],
        description: () => <p>{description1 && description1.node[lang]}</p>,
        ctaText: "€ 18.50/μήνα | Ξεκίνα τώρα",
        ctaLink: "/vitamin",
        ctaBG: "black",
        priceButton: true,
        price: lang === "gr" ? "€ 18.50/μήνα" : "€18.50/month",
        image: "/petri.jpg",
      },
      {
        title: title2 && title2.node[lang],
        description: () =>
          description2 &&
          description2.node[lang]
            .split("\n")
            .map(el => <p style={{ margin: "0" }}>{el}</p>),
        ctaText: btntext1 && btntext1.node[lang],
        ctaLink: "/vitamin",
        image: "/package.jpg",
      },
      {
        title: title3 && title3.node[lang],
        description: () => (
          <p>
            {description3 &&
              description3.node[lang]
                .split(" ")
                .slice(0, 17)
                .map(el => el)
                .join(" ")}{" "}
            {description3 &&
              description3.node[lang]
                .split(" ")[17]
                .split(".")
                .slice(0, 1)
                .map(el => el)}
            .
            <br />
            <br />
            {description3 &&
              description3.node[lang]
                .split(" ")[17]
                .split(".")
                .slice(1)
                .map(el => el)}{" "}
            {description3 &&
              description3.node[lang]
                .split(" ")
                .slice(18, 19)
                .map(el => el)
                .join(" ")}{" "}
            <strong>Great</strong>{" "}
            {description3 &&
              description3.node[lang]
                .split(" ")
                .slice(20)
                .map(el => el)
                .join(" ")}
          </p>
        ),
        ctaText: "€ 18.50/μήνα | Ξεκίνα τώρα",
        ctaBG: "black",
        priceButton: true,
        price: lang === "gr" ? "€ 18.50/μήνα" : "€18.50/month",
        ctaLink: "/vitamin",
        image: "/package2.jpg",
      },
      {
        title: title4 && title4.node[lang],
        description: () => <p>{description4 && description4.node[lang]}</p>,
        ctaText: btntext1 && btntext1.node[lang],
        ctaLink: "/vitamin",
        image: "/wellbeing2.jpg",
      },
      // {
      //   title: "Messenger assistant",
      //   description: () => (
      //     <p>
      //       Mερικές φορές χρειάζεσαι ένα μικρό push! Σου στέλνουμε DM για να
      //       θυμάσαι να παίρνεις τις Great σου κάθε μέρα.
      //     </p>
      //   ),
      //   ctaText: "Συνδέσου",
      //   ctaLink: "/vitamin",
      //   // image: "/package.jpg",
      //   ctaBG: "black",
      // },
      {
        title: title5 && title5.node[lang],
        description: () => <p>{description5 && description5.node[lang]}</p>,
        ctaText: btntext2 && btntext2.node[lang],
        ctaLink: "/vitamin",
        image: "/immune3.jpg",
      },
      {
        title: title6 && title6.node[lang],
        description: () => <p>{description6 && description6.node[lang]}</p>,
        ctaText: "€ 18.50/μήνα | Ξεκίνα τώρα",
        ctaBG: "black",
        priceButton: true,
        price: lang === "gr" ? "€ 18.50/μήνα" : "€18.50/month",
        ctaLink: "/vitamin",
        image: "/laterelease.jpg",
      },
    ],
  };

  return (
    <Layout>
      <SEO
        title="Great Η καλύτερη 
γυναικεία πολυβιταμίνη"
      />
      <VitaminMainCTA data={data.allLabels.edges} />
      <div className="desktopOnly">
        <VitaminIntro data={data.allLabels.edges} />
      </div>
      <Timeline stages={stages} data={data.allLabels.edges} />
      <Benefits
        header={benefits.header}
        extraClass="vitaminPage"
        benefits={benefits.items}
        startTextLeft={true}
        lang={lang}
      />
      <div className="container">
        <IngredientsHolder rotate={false} lang={lang} />
      </div>
      <Experts lang={lang} data={data} />
      <QA qas={data} lang={lang} />
      <div className="desktopOnly">
        <VitaminMainCTA data={data.allLabels.edges} />
      </div>
      <div className="mobileOnly">
        <VitaminIntro data={data.allLabels.edges} />
        <HalfButton2
          price={lang === "gr" ? "€ 18.50/μήνα" : "€18.50/month"}
          lang={lang}
          data={data.allLabels.edges}
        />
        <Guarantees
          data={data.allLabels.edges} lang={lang}
        />
        <div className="hr mt160">&nbsp;</div>
      </div>
      <Follow lang={lang} data={data.allLabels.edges} />
    </Layout>
  );
};

export default SecondPage;

export const query = graphql`
  query {
    allLabels {
      edges {
        node {
          label
          gr
          en
        }
      }
    }
    allContentfulFaq(
      filter: { showOnHomepage: { eq: true } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          questionInEnglish
          question
          answerInEnglish {
            answerInEnglish
          }
          answ {
            answ
          }
          order
        }
      }
    }
  }
`;
